@font-face {
  font-family: 'Myriad Pro';
  font-weight: 400;
  src: url(./fonts/Myriad\ Pro\ Regular.ttf);
}

@font-face {
  font-family: 'Myriad Pro';
  font-weight: 600;
  src: url(./fonts/Myriad\ Pro\ Semibold.ttf);
}

@font-face {
  font-family: 'Assistant';
  font-weight: 400;
  src: url(./fonts/Assistant-Regular.ttf);
}

@font-face {
  font-family: 'Assistant';
  font-weight: 600;
  src: url(./fonts/Assistant-SemiBold.ttf);
}

@font-face {
  font-family: 'omnes-arabic';
  font-weight: 400;
  src: url(./fonts/Omnes\ regular.otf);
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Toastify__toast-container.toast-container {
  width: 460px;
}

.Toastify__toast-container--top-center.toast-container {
  width: auto;
}

@media (max-width: 480px) {
  .Toastify__toast-container--top-right.toast-container {
    width: auto;
    padding: 0 24px 24px;
    bottom: 0;
    right: 0;
    top: auto;
  }
}
